<template>
  <CustomModal
    class="about-modal"
    name="about"
    :title="`${$t('Request an Appointment')}`"
  >
    <div class="about-modal__content">
      <div class="about-modal__content__subtitle">
        {{
          $t(
            'Appointments can be made Monday to Friday from 9 a.m. to 1 p.m. and 2 p.m. to 6 p.m. or on Saturday from 9 a.m. to 1 p.m. Reservations are compulsory.'
          )
        }}
      </div>
      <ContactForm
        :formspree-form-id="aboutUsFormId"
        title="form about"
        @onSubmit="closeModal('about')"
      />
    </div>
  </CustomModal>
</template>
<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import ContactForm from '~/components/Contact/ContactForm.vue';
import CustomModal from '~/components/General/CustomModal.vue';

export default defineComponent({
  name: 'AboutModal',
  components: {
    CustomModal,
    ContactForm,
  },
  setup() {
    const aboutUsFormId = ref(process.env.formspreeAboutPageFormId);
    const { closeModal } = useUiState();
    return {
      aboutUsFormId,
      closeModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.about-modal {
  &__content {
    &__subtitle {
      @include paragraph-m;
      margin-bottom: 1.25rem;
    }

    &__form {
      &__input {
        &:not(:last-child) {
          margin-bottom: 0.625rem;
        }

        &:last-child {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.about-modal.sf-modal {
  @include from-desktop-min {
    .sf-modal {
      &__container {
        width: calc(100% - 2.5rem);
        margin: auto 0;
        justify-content: center;
        max-width: 64rem;
      }
    }
  }
}
</style>
