<template>
  <div>
    <QuoteModal :is-contract-page="isContractPage" />
    <PdpContactUsModal />
    <SubscribeModal />
    <PdpGalleryModal />
    <CustomizationModal />
    <GeoLocatorModal
      v-if="localizedCountry"
      :localized-country="localizedCountry"
    />
    <AboutModal />
  </div>
</template>

<script>
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import {
  QuoteModal,
  SubscribeModal,
  PdpGalleryModal,
  CustomizationModal,
  GeoLocatorModal,
} from '.';
import AboutModal from '~/components/Modals/About/AboutModal.vue';
import PdpContactUsModal from '~/components/Modals/ProductPage/ContactUsModal.vue';

export default defineComponent({
  name: 'Modals',
  components: {
    PdpContactUsModal,
    AboutModal,
    QuoteModal,
    SubscribeModal,
    PdpGalleryModal,
    CustomizationModal,
    GeoLocatorModal,
  },
  props: {
    localizedCountry: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const route = useRoute();
    const isContractPage = computed(() =>
      route.value.fullPath.includes('contract')
    );
    return { isContractPage };
  },
});
</script>
