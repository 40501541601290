<template>
  <CustomModal
    class="customization-modal"
    name="customization"
    :title="$t('Request a customization')"
  >
    <div v-show="!loading" class="customization-modal__content">
      <div class="customization-modal__content__subtitle">
        {{
          $t(
            'The size, color and finishes of this product can be customized according to your needs. Tell us what you would like and our team will get back to you within hours.'
          )
        }}
      </div>
      <ValidationObserver key="customization" v-slot="{ handleSubmit, valid }">
        <form
          class="customization-modal__content__form"
          @submit.prevent="handleSubmit(handleSendForm)"
        >
          <CustomInput
            v-model="form.name"
            input-name="customization-name"
            input-id="customization-name"
            :input-label="$t('Name')"
            validate-rule="required"
            class="customization-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'name' }"
            @inputChange="(value) => inputChange('firstname', value)"
          />
          <CustomInput
            v-model="form.lastName"
            input-name="customization-last-name"
            input-id="customization-last-name"
            :input-label="$t('Last name')"
            validate-rule="required"
            class="customization-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'last-name' }"
            @inputChange="(value) => inputChange('lastname', value)"
          />
          <CustomInput
            v-model="form.email"
            input-name="customization-email"
            input-id="customization-email"
            :input-label="$t('Email')"
            validate-rule="required|email"
            class="customization-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'email' }"
            @inputChange="(value) => inputChange('email', value)"
          />
          <CustomInput
            v-model="form.destination"
            input-name="customization-destination"
            input-id="customization-destination"
            :input-label="$t('Shipping country')"
            validate-rule="required"
            class="customization-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'destination' }"
            @inputChange="(value) => inputChange('destination', value)"
          />
          <CustomInput
            v-model="form.company"
            input-name="customization-company"
            input-id="customization-company"
            :input-label="$t('Company')"
            class="customization-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'company' }"
            @inputChange="(value) => inputChange('company', value)"
          />
          <CustomTextarea
            v-model="form.message"
            name="customization-message"
            textarea-id="customization-message"
            :label="$t('Message')"
            validate-rule="required|max:1000"
            class="customization-modal__content__form__input"
            :style="{ 'grid-area': 'message' }"
            @onChange="(value) => inputChange('message', value)"
          />
          <div
            class="customization-modal__content__form__input"
            :style="{ 'grid-area': 'checkbox' }"
          >
            <CustomCheckbox
              validate-rule="required"
              @checkboxChange="
                (value) => inputChange('privacy_policy', `${value}`)
              "
            />
          </div>
          <CustomCta
            class="customization-modal__content__form__input"
            :disabled="!valid"
            type="submit"
            theme="dark"
            :is-full-width="!isDesktop"
            :style="{ 'grid-area': 'submit' }"
          >
            {{ $t('Submit') }}
          </CustomCta>
        </form>
      </ValidationObserver>
    </div>
    <LoadingDots v-show="loading" />
  </CustomModal>
</template>

<script>
import { ref, defineComponent } from '@nuxtjs/composition-api';
import {
  CustomCta,
  CustomInput,
  CustomModal,
  CustomCheckbox,
  CustomTextarea,
  LoadingDots,
} from '~/components/General/';
import { ValidationObserver } from 'vee-validate';
import { useUiState, useWindow, useFormspree, useGtm } from '~/composables';

export default defineComponent({
  name: 'CustomizationModal',
  components: {
    CustomCta,
    CustomInput,
    CustomModal,
    CustomCheckbox,
    CustomTextarea,
    ValidationObserver,
    LoadingDots,
  },
  setup() {
    const form = ref({});
    const loading = ref(false);
    const { isDesktop } = useWindow();
    const { closeModal, getModalData } = useUiState();
    const formspreeFormId = ref(
      process.env.formspreeProductPersonalizationFormId
    );
    const { sendDataToFormspree, hasSubmitError } = useFormspree(
      formspreeFormId.value
    );
    const { formActionGtmPush } = useGtm();
    const handleSendForm = async () => {
      loading.value = true;
      await sendDataToFormspree(
        { ...form.value, ...JSON.parse(getModalData()) },
        closeModal
      );
      formActionGtmPush(
        'GA4-EVENT-FORM REQUEST A PERSONALIZATION-HEVELOP',
        hasSubmitError.value
      );
      loading.value = false;
    };

    const inputChange = (field, value) => {
      const inputElement = document.querySelector(
        `#${field}-${formspreeFormId.value}`
      );
      if (formspreeFormId.value && inputElement) {
        inputElement.value = value;
      }
    };

    return {
      form,
      loading,
      isDesktop,
      formspreeFormId,
      handleSendForm,
      inputChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.customization-modal {
  &__content {
    &__subtitle {
      @include paragraph-m;
      margin-bottom: 1.25rem;
    }
    &__form {
      &__input {
        &:not(:last-child) {
          margin-bottom: 0.625rem;
        }
        &:last-child {
          margin-top: 1.25rem;
        }
      }
    }
  }
  @include from-desktop-min {
    &__content {
      &__form {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 0.625rem;
        grid-template-areas:
          'name last-name'
          'email  destination'
          'company .'
          'message message'
          'message message'
          'message message'
          'checkbox attachment'
          'submit .';
      }
    }
  }
}
</style>

<style lang="scss">
.customization-modal.sf-modal {
  @include from-desktop-min {
    .sf-modal {
      &__container {
        width: calc(100% - 2.5rem);
        margin: auto 0;
        justify-content: center;
        max-width: 64rem;
      }
    }
  }
}
</style>
