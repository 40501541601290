<template>
  <CustomModal
    class="geolocator-modal"
    name="geolocator"
    :title="$t('Geolocation')"
    :persistent="true"
  >
    <div class="geolocator-modal-content">
      <div class="geolocator-modal-body">
        <div class="geolocator-modal-text-top">
          <p>
            {{ $t('You seem to be connected from ')
            }}<b>{{ $t(`${countryLabel}`) }}</b>
          </p>
          <nuxt-img
            v-if="countryFlag"
            :src="countryFlag"
            format="webp"
            width="20px"
            height="13px"
            alt="flag"
            loading="lazy"
          />
        </div>
        <p>{{ $t('Do you wish to switch country?') }}</p>
      </div>
      <div class="geolocator-modal-bottom">
        <CustomCta
          v-if="countryCode === locale"
          @click="
            () => {
              updateLocalData(true);
              closeModal('geolocator');
            }
          "
          ><span>{{ $t('Accept suggestion') }}</span>
        </CustomCta>
        <a
          v-else
          class="geolocator-modal-cta-accept"
          :href="`/${localePathString}`"
          @click="
            () => {
              updateLocalData(true);
              closeModal('geolocator');
            }
          "
        >
          {{ $t('Accept suggestion ') }}
        </a>
        <CustomCta
          @click="
            () => {
              updateLocalData(false);
              closeModal();
              openSidebar('country');
            }
          "
        >
          <span>
            {{ $t('Chose manually') }}
          </span>
        </CustomCta>
      </div>
    </div>
  </CustomModal>
</template>
<script>
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import {
  defineComponent,
  ref,
  computed,
  useContext,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api';
import { CustomCta } from '~/components/General/index';
import { useI18n } from '~/helpers/hooks/usei18n';
import { marketToLocaleMapper } from '~/helpers/util';
import { CustomModal } from '~/components/General/';

export default defineComponent({
  name: 'GeoLocatorModal',
  components: { SfModal, CustomModal, CustomCta },
  props: {
    localizedCountry: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { closeModal, openSidebar } = useUiState();
    const { locale } = useI18n();
    const {
      app: { i18n },
    } = useContext();
    const countryData = reactive({
      countryLabel: props.localizedCountry?.label,
      countryFlag: props.localizedCountry?.flag,
      countryCode: props.localizedCountry?.code,
    });
    const countryCookieName = 'vsf-localized-country';
    const language = computed(() => (locale === 'it' ? 'it' : 'en'));
    const mappedMarkets = ref([]);
    const localeToMarketObject = ref({});
    mappedMarkets.value = marketToLocaleMapper(i18n, language.value);
    /* Saves mapped markets in a js object to access them easily using only the
      locale code */
    mappedMarkets.value.forEach((market) => {
      if (
        (market?.countryIso === 'it' && market.value.split('-')[1] === 'it') ||
        market.value.split('-')[1] === 'en'
      ) {
        localeToMarketObject.value[market?.countryIso] = market;
      }
    });
    /* Sets a key if the modal was interacted with, also sets the default
      key for selected country if user closes modal */
    const updateLocalData = (setSecondKey) => {
      if (process && process.client && localStorage) {
        /* Safety check if vsf-country-selected is not set correctly or in case
          of unhandled error, interacting with modal will hide it */
        if (localStorage.getItem(countryCookieName) === null) {
          localStorage.setItem(
            countryCookieName,
            JSON.stringify({ interacted: true })
          );
        } else {
          const localData = JSON.parse(localStorage.getItem(countryCookieName));
          localStorage.setItem(
            countryCookieName,
            JSON.stringify({ ...localData, interacted: true })
          );
        }
        if (
          setSecondKey &&
          localeToMarketObject.value?.[props.localizedCountry?.code]
        ) {
          localStorage.setItem(
            'vsf-country-selected',
            JSON.stringify(
              localeToMarketObject.value?.[props.localizedCountry?.code]
            )
          );
        }
      }
    };
    const localePathString = computed(
      () => localeToMarketObject.value?.[props.localizedCountry?.code]?.value
    );
    return {
      ...toRefs(countryData),
      locale,
      closeModal,
      openSidebar,
      updateLocalData,
      localeToMarketObject,
      localePathString,
    };
  },
});
</script>
<style lang="scss">
.geolocator-modal {
  --modal-index: 101;
  --overlay-z-index: 101;
  .sf-modal {
    &__container {
      .geolocator-modal-top {
        p {
          display: none;
        }
      }
      .sf-modal__content {
        .geolocator-modal {
          &-body {
            margin-top: 1.5rem;

            text-align: center;
            .geolocator-modal-text-top {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
            }
          }
          &-bottom {
            row-gap: 15px;
            display: grid;
            flex-direction: column;
            gap: 1.5rem;
            margin: 1rem 1rem 0;
            .geolocator-modal-cta-accept {
              @include paragraph-m;
              background-color: var(--c-black);
              color: var(--c-white);
              border: none;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: var(--font-weight-medium);
              width: 100%;
              height: var(--button-height);
              border-radius: var(--border-radius);
              button {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}
@include from-desktop-min {
  .geolocator-modal {
    --modal-width: 50vw;
    --modal-max-height: 25rem;
    p {
      @include paragraph-m;
    }
    .sf-modal {
      &__container {
        min-height: 15rem;
        .geolocator-modal-top {
          display: flex;
          justify-content: space-between;
          margin: 2rem 4rem;
          p {
            margin: 0;
          }
        }
        .sf-modal__content {
          .geolocator-modal {
            &-body {
              margin-top: 2rem;
              text-align: center;
            }
            &-bottom {
              flex-direction: row;
              margin: 2.5rem 1rem;
            }
          }
        }
      }
    }
  }
}
</style>
