<template>
  <CustomModal class="quote-modal" name="quote" :title="$t('Request a quote')">
    <div v-show="!loading" class="quote-modal__content">
      <ValidationObserver key="quote" v-slot="{ handleSubmit, valid }">
        <form
          class="quote-modal__content__form"
          @submit.prevent="handleSubmit(handleSendForm)"
        >
          <CustomInput
            v-model="form.firstname"
            input-name="quote-name"
            input-id="quote-name"
            :input-label="$t('Name')"
            validate-rule="required"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'name' }"
            @inputChange="(value) => inputChange('firstname', value)"
          />
          <CustomInput
            v-model="form.lastname"
            input-name="quote-last-name"
            input-id="quote-last-name"
            :input-label="$t('Last name')"
            validate-rule="required"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'last-name' }"
            @inputChange="(value) => inputChange('lastname', value)"
          />
          <CustomInput
            v-model="form.email"
            input-name="quote-email"
            input-id="quote-email"
            :input-label="$t('Email')"
            validate-rule="required|email"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'email' }"
            @inputChange="(value) => inputChange('email', value)"
          />
          <CustomInput
            v-model="form.repeatEmail"
            input-name="quote-repeat-email"
            input-id="quote-repeat-email"
            :input-label="$t('Repeat Email')"
            validate-rule="required|confirmed:quote-email"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'repeat-email' }"
          />
          <CustomInput
            v-model="form.company"
            input-name="quote-company"
            input-id="quote-company"
            :input-label="$t('Company')"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'company' }"
            @inputChange="(value) => inputChange('company', value)"
          />
          <CustomInput
            v-model="form.phone"
            input-name="quote-phone"
            input-id="quote-phone"
            :input-label="$t('Phone number')"
            validate-rule="numeric"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'phone' }"
            @inputChange="(value) => inputChange('phone', value)"
          />
          <CustomInput
            v-model="form.destination"
            input-name="quote-destination"
            input-id="quote-destination"
            :input-label="$t('Shipping country')"
            class="quote-modal__content__form__input"
            :full-width="true"
            :style="{ 'grid-area': 'destination' }"
            @inputChange="(value) => inputChange('destination', value)"
          />
          <CustomTextarea
            v-model="form.message"
            name="quote-message"
            textarea-id="quote-message"
            :label="$t('Message')"
            validate-rule="required|max:1000"
            class="quote-modal__content__form__input"
            :style="{ 'grid-area': 'message' }"
            @onChange="(value) => inputChange('message', value)"
          />
          <div
            class="quote-modal__content__form__input"
            :style="{ 'grid-area': 'checkbox' }"
          >
            <CustomCheckbox
              validate-rule="required"
              @checkboxChange="
                (value) => inputChange('privacy_policy', `${value}`)
              "
            />
          </div>
          <CustomCta
            class="quote-modal__content__form__input"
            :disabled="!valid"
            type="submit"
            theme="dark"
            :is-full-width="!isDesktop"
            :style="{ 'grid-area': 'submit' }"
          >
            {{ $t('Submit') }}
          </CustomCta>
        </form>
      </ValidationObserver>
    </div>
    <LoadingDots v-show="loading" />
  </CustomModal>
</template>

<script>
import { ref, defineComponent } from '@nuxtjs/composition-api';
import {
  CustomCta,
  CustomInput,
  CustomModal,
  CustomCheckbox,
  CustomTextarea,
  LoadingDots,
} from '~/components/General/';
import { ValidationObserver } from 'vee-validate';
import { useWindow, useUiState, useFormspree, useGtm } from '~/composables';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';

export default defineComponent({
  name: 'QuoteModal',
  props: {
    isContractPage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomCta,
    CustomInput,
    CustomModal,
    CustomCheckbox,
    CustomTextarea,
    ValidationObserver,
    LoadingDots,
  },
  setup(props) {
    const form = ref({});
    const loading = ref(false);
    const { isDesktop } = useWindow();
    const { closeModal, getModalData } = useUiState();
    const formspreeFormId = ref(
      process.env.formspreeProductRequestAQuoteFormId
    );
    const { sendDataToFormspree, hasSubmitError } = useFormspree(
      formspreeFormId.value
    );
    const { formActionGtmPush } = useGtm();
    const handleSendForm = async () => {
      loading.value = true;
      await sendDataToFormspree(
        {
          ...form.value,
          ...JSON.parse(getModalData()),
          source:
            isClient && window?.location?.href
              ? window.location.href
              : 'Not available',
        },
        closeModal
      );
      formActionGtmPush(
        props.isContractPage
          ? `GA4-EVENT-FORM REQUEST A QUOTE CONTRACT-HEVELOP`
          : `GA4-EVENT-FORM REQUEST A QUOTE PRODUCT-HEVELOP`,
        hasSubmitError.value
      );
      loading.value = false;
    };

    const inputChange = (field, value) => {
      const inputElement = document.querySelector(
        `#${field}-${formspreeFormId.value}`
      );
      if (formspreeFormId.value && inputElement) {
        inputElement.value = value;
        if (field === 'phone') {
          inputElement.focus();
          document.querySelector(`#message-${formspreeFormId.value}`).focus();
          document.querySelector(`#quote-phone`).focus();
        }
      }
    };

    return {
      form,
      loading,
      isDesktop,
      formspreeFormId,
      handleSendForm,
      inputChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.quote-modal {
  &__content {
    &__form {
      &__input {
        &:not(:last-child) {
          margin-bottom: 0.625rem;
        }
        &:last-child {
          margin-top: 1.25rem;
        }
      }
    }
  }
  @include from-desktop-min {
    &__content {
      &__form {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 0.625rem;
        grid-template-areas:
          'name last-name'
          'email  repeat-email'
          'company phone'
          'destination destination'
          'message message'
          'message message'
          'checkbox attachment'
          'submit .';
      }
    }
  }
}
</style>

<style lang="scss">
.quote-modal.sf-modal {
  @include from-desktop-min {
    .sf-modal {
      &__container {
        width: calc(100% - 2.5rem);
        margin: auto 0;
        justify-content: center;
        max-width: 64rem;
      }
    }
  }
}
</style>
