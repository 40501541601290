<template>
  <CustomModal
    class="pdp-gallery-modal"
    :name="modalName"
    :full-page="true"
    v-if="images && images.length > 0"
  >
    <div
      v-if="images.length === 1 && images[0]"
      class="pdp-gallery-modal__single-image"
    >
      <nuxt-img
        class="pdp-gallery-modal__single-image__image"
        :src="images[0].url"
      />
    </div>
    <div v-else-if="images.length > 1" class="pdp-gallery-modal__images">
      <swiper-container init="false" class="pdp-gallery-modal__images__swiper">
        <swiper-slide
          v-for="(image, index) in images"
          :key="index"
          class="pdp-gallery-modal__images__swiper__slide"
        >
          <nuxt-img
            :src="image.url"
            class="pdp-gallery-modal__images__swiper__slide__image"
          />
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="pdp-gallery-modal__back">
      <BackButton @click="closeModal()" />
    </div>
    <div class="pdp-gallery-modal__message" v-if="message">
      {{ message }}
    </div>
    <div class="pdp-gallery-modal__navigation">
      <nuxt-img
        v-for="(image, index) in images"
        :key="index"
        :src="image.url"
        class="pdp-gallery-modal__navigation__item"
        :class="{ 'selected-item': currentItemIndex === index }"
        @click="changeItem(index)"
      />
    </div>
  </CustomModal>
</template>

<script>
import { ref, watch, computed, defineComponent } from '@nuxtjs/composition-api';
import { BackButton } from '~/components/General/Icons';
import { CustomModal } from '~/components/General/';
import { useUiState, useWindow } from '~/composables';

export default defineComponent({
  name: 'PdpGalleryModal',
  components: {
    CustomModal,
    BackButton,
  },
  setup() {
    const { closeModal, openedModal, modalData } = useUiState();
    const { isDesktop } = useWindow();

    const modalName = ref('pdp-gallery');
    const pdpGallerySwiper = ref();
    const currentIndex = ref('');

    const images = computed(() => {
      return modalData.value?.images;
    });
    const message = computed(() => modalData.value?.message);
    const currentItemIndex = computed(() =>
      typeof currentIndex.value === 'number'
        ? currentIndex.value
        : typeof modalData.value.currentImageIndex === 'number'
        ? modalData.value.currentImageIndex
        : 0
    );

    const changeItem = (index) => {
      pdpGallerySwiper.value.setAttribute('allowTouchMove', true);
      pdpGallerySwiper.value.swiper.slideTo(index);
      pdpGallerySwiper.value.setAttribute('allowTouchMove', false);
      currentIndex.value = index;
    };

    watch(openedModal, (newValue) => {
      if (newValue && newValue === modalName.value) {
        setTimeout(function () {
          const swiperElement = document.querySelectorAll(
            '.pdp-gallery-modal__images__swiper'
          )[0];
          if (swiperElement) {
            const swiperParams = {
              loop: 'true',
              direction: isDesktop.value ? 'vertical' : 'horizontal',
              allowTouchMove: false,
              initialSlide: currentItemIndex.value,
            };
            Object.assign(swiperElement, swiperParams);
            swiperElement.initialize();
            pdpGallerySwiper.value = swiperElement;
          }
        }, 0);
      } else {
        if (pdpGallerySwiper.value) {
          if (pdpGallerySwiper.value.swiper) {
            pdpGallerySwiper.value.swiper.destroy();
          }
          pdpGallerySwiper.value = undefined;
        }
        currentIndex.value = '';
      }
    });

    return {
      images,
      message,
      modalName,
      isDesktop,
      currentItemIndex,
      closeModal,
      changeItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.pdp-gallery-modal {
  position: relative;
  &__single-image {
    height: 100vh;
    width: 100%;
    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__images {
    height: 100vh;
    &__swiper {
      &__slide {
        height: 100vh;
        &__image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &__back {
    z-index: 1;
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    cursor: pointer;
  }
  &__message {
    z-index: 1;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    @include label-s;
    text-transform: uppercase;
    text-align: right;
    max-width: 7.5rem;
  }
  &__navigation {
    z-index: 1;
    position: absolute;
    bottom: 1.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    width: fit-content;
    max-width: calc(100vw - 2.5rem);
    min-width: 4.6875rem;
    min-height: 4.6875rem;
    overflow-x: auto;
    &__item {
      width: 4.5625rem;
      height: 4.5625rem;
      border: var(--general-border);
      &:not(.selected-item) {
        border-color: var(--c-white);
      }
    }
  }
  @include from-desktop-min {
    &__single-image {
      height: 100vh;
      width: 100%;
      &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__images {
      &__swiper {
        width: 100%;
        height: 100%;
        &__slide {
          height: 100vh;
          width: 100vw;
          &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &__message {
      max-width: 13.75rem;
    }
    &__navigation {
      width: 4.6875rem;
      height: fit-content;
      bottom: 0;
      top: 0;
      margin: auto 0;
      left: 1.25rem;
      flex-direction: column;
    }
  }
}
</style>
