<template>
  <CustomModal
    class="subscribe-modal"
    name="subscribe"
    @close="handleClose"
    :image="canShowModal ? newsletterModalContent.data.image : null"
  >
    <div v-if="canShowModal" class="subscribe-modal__content">
      <div class="subscribe-modal__content__text">
        {{ newsletterModalContent.data.text }}
      </div>
      <NewsletterForm form-key="newsletter-popup" @success="closeModal()" />
    </div>
  </CustomModal>
</template>

<script>
import {
  ref,
  computed,
  useFetch,
  onMounted,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import { CustomModal } from '~/components/General/';
import { useUiState, contentGetters } from '~/composables';
import { NewsletterForm } from '~/components/Newsletter';
import { useContent, useUser } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'SubscribeModal',
  components: {
    NewsletterForm,
    CustomModal,
  },
  setup() {
    const {
      app: {
        i18n: {
          localeProperties: { localeValue },
        },
      },
      $cookies,
    } = useContext();
    const { blocks, loadBlocks } = useContent(`NewsletterModal-${localeValue}`);
    const newsletterModalSelector = `newsletter-modal-${localeValue}`;
    const newsletterModalContent = ref();
    const { openModal, closeModal, openedModal, openedSidebar } = useUiState();
    const cookieName = 'subscribe-modal-shown';
    const subscribeModalShown = ref(false);
    const mounted = ref(false);
    const { user, isAuthenticated } = useUser();

    const openSubscribeModal = () => {
      if (!openedSidebar.value && !openedModal.value) {
        if (
          !isAuthenticated.value ||
          (user.value && !user.value.is_subscribed)
        ) {
          openModal('subscribe');
        }
      } else {
        setTimeout(() => openSubscribeModal(), 10000); // eslint-disable-line unicorn/numeric-separators-style
      }
    };

    const canShowModal = computed(() => {
      if (
        !subscribeModalShown.value &&
        mounted.value &&
        !openedSidebar.value &&
        !openedModal.value &&
        !$cookies.get(cookieName) &&
        newsletterModalContent.value &&
        newsletterModalContent.value.active &&
        newsletterModalContent.value.data
      ) {
        setTimeout(() => openSubscribeModal(), 10000); // eslint-disable-line unicorn/numeric-separators-style
        subscribeModalShown.value = true;
      }
      return subscribeModalShown.value;
    });

    useFetch(async () => {
      try {
        await loadBlocks({ identifiers: [newsletterModalSelector] });
        const blockContent = contentGetters.getCmsBlockContent(blocks.value);
        if (Object.keys(blockContent).length > 0)
          newsletterModalContent.value = blockContent[newsletterModalSelector];
      } catch {
        console.warn(`Could not get ${newsletterModalSelector} block content`);
      }
    });

    onMounted(() => {
      mounted.value = true;
    });

    const handleClose = () => {
      $cookies.set(cookieName, 1, { maxAge: 60 * 60 * 24 });
    };

    return {
      canShowModal,
      newsletterModalContent,
      closeModal,
      handleClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.subscribe-modal {
  &__content {
    &__text {
      @include mobile-h6;
    }
  }
  @include from-desktop-min {
    &__content {
      &__text {
        @include desktop-h6;
      }
    }
  }
}
</style>
