var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomModal',{staticClass:"geolocator-modal",attrs:{"name":"geolocator","title":_vm.$t('Geolocation'),"persistent":true}},[_c('div',{staticClass:"geolocator-modal-content"},[_c('div',{staticClass:"geolocator-modal-body"},[_c('div',{staticClass:"geolocator-modal-text-top"},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('You seem to be connected from '))),_c('b',[_vm._v(_vm._s(_vm.$t(("" + _vm.countryLabel))))])]),_vm._v(" "),(_vm.countryFlag)?_c('nuxt-img',{attrs:{"src":_vm.countryFlag,"format":"webp","width":"20px","height":"13px","alt":"flag","loading":"lazy"}}):_vm._e()],1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Do you wish to switch country?')))])]),_vm._v(" "),_c('div',{staticClass:"geolocator-modal-bottom"},[(_vm.countryCode === _vm.locale)?_c('CustomCta',{on:{"click":function () {
            _vm.updateLocalData(true);
            _vm.closeModal('geolocator');
          }}},[_c('span',[_vm._v(_vm._s(_vm.$t('Accept suggestion')))])]):_c('a',{staticClass:"geolocator-modal-cta-accept",attrs:{"href":("/" + _vm.localePathString)},on:{"click":function () {
            _vm.updateLocalData(true);
            _vm.closeModal('geolocator');
          }}},[_vm._v("\n        "+_vm._s(_vm.$t('Accept suggestion '))+"\n      ")]),_vm._v(" "),_c('CustomCta',{on:{"click":function () {
            _vm.updateLocalData(false);
            _vm.closeModal();
            _vm.openSidebar('country');
          }}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('Chose manually'))+"\n        ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }